<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-0 pl-5 pr-4>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-sheet class="dense-inputs">
                        <v-row no-gutters class="pb-3">
                            <v-col cols="12" lg="12" md="12" xs="12" class="d-flex flex-row justify-space-between">
                                <div class="col-md-4 col-xs-6 d-flex flex-row no-gutters pa-0">
                                    <v-text-field
                                        :label="$t('message.filterResults')"
                                        :value="searchTerm"
                                        autocomplete="off"
                                        class="mt-2 mb-0 force-text-left"
                                        hide-details="auto"
                                        prepend-inner-icon="mdi-filter-outline"
                                        @change="searchTerm = $event"
                                    ></v-text-field>
                                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                                    <v-btn class="ml-3" @click="resetSearch()"><v-icon>refresh</v-icon></v-btn>
                                </div>
                                <v-row no-gutters>
                                    <v-col cols="12" class="pt-1 d-flex flex-row align-center justify-end">
                                        <div class="font-sm mr-3">
                                            <b>DS</b> - Direct Sale
                                        </div>
                                        <div class="font-sm mr-3">
                                            <b>CS</b> - Comm Sale
                                        </div>
                                        <div class="font-sm mr-3">
                                            <b>WH</b> - WH Sale
                                        </div>
                                        <div class="font-sm mr-3">
                                            <b>PO</b> - WH Purchase
                                        </div>
                                        <div class="font-sm">
                                            <v-icon small color="orange darken-2">fiber_manual_record</v-icon>{{ $t('message.resultsBy') }}
                                        </div>
                                        <div class="font-sm ml-3">
                                            <v-icon small color="blue lighten-2">fiber_manual_record</v-icon>{{ $t('message.basedOn') }}
                                        </div>
                                    </v-col>
                                </v-row>
                                <ExportTableReportDetail
                                    :export-conditions="exportConditions"
                                    :export-data="reportResults"
                                    :export-fields="headers"
                                    class="ml-3"
                                ></ExportTableReportDetail>
                            </v-col>
                        </v-row>
                    </v-sheet>
                    <v-overlay
                        :value="loading.fetch"
                        absolute
                        opacity="0.15"
                    >
                        <v-row>
                            <v-col class="text-center">
                                <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                                ></v-progress-circular>
                            </v-col>
                        </v-row>
                    </v-overlay>
                    <v-data-table
                        :headers="headers"
                        :items="reportResults"
                        :items-per-page="-1"
                        :search="searchTerm"
                        :height="tableHeight"
                        calculate-widths
                        class="appic-table-light specification-tables"
                        dense
                        hide-default-footer
                        id="reportDetailTable"
                        item-key="MarketingReport.id"
                        disable-pagination
                        fixed-header
                        sort-by="MarketingReport.etd"
                        sort-asc
                    >
                        <template v-slot:item.MarketingReport.id="{ item }">
                            <v-menu>
                                <template v-slot:activator="{ on: menu }">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                                <v-icon>more_vert</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('message.moreActions') }}</span>
                                    </v-tooltip>
                                </template>
                                <v-list dense>
                                    <v-list-item class="font-sm" @click="viewPdf(item)" v-if="['DS','SS','CS'].includes(item.MarketingReport.type) && $can('read','Contract')"><v-icon small color="red lighten-1" class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.contract') }}</v-list-item>
                                    <v-list-item class="font-sm" @click="viewPdf(item)" v-if="['PO'].includes(item.MarketingReport.type) && $can('read','Po')"><v-icon small color="red lighten-1" class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.po') }}</v-list-item>
                                    <v-list-item class="font-sm" @click="viewWhSalesInvoicePdf(item)" v-if="item.MarketingReport.type == 'WH' && $can('read','Invoice')"><v-icon small color="red lighten-1" class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.invoice') }}</v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                        <template v-slot:item.MarketingReport.type="{item}">
                            <span class="font-weight-bold">{{ item.MarketingReport.type }}</span>
                        </template>
                        <template v-slot:item.MarketingReport.buyer="{item}">
                            <div class="d-flex flex-row">
                                <v-icon small color="orange darken-2" v-if="resultBy == 'customer_id'">fiber_manual_record</v-icon>
                                <v-icon small color="blue lighten-2" v-if="basedOn == 'customer_id'">fiber_manual_record</v-icon>
                                <span>{{ item.MarketingReport.buyer }}</span>
                            </div>
                        </template>
                        <template v-slot:item.MarketingReport.supplier="{item}">
                            <div class="d-flex flex-row">
                                <v-icon small color="orange darken-2" v-if="resultBy == 'supplier_id'">fiber_manual_record</v-icon>
                                <v-icon small color="blue lighten-2" v-if="basedOn == 'supplier_id'">fiber_manual_record</v-icon>
                                <span>{{ item.MarketingReport.supplier }}</span>
                            </div>
                        </template>
                        <template v-slot:item.MarketingReport.marketing="{item}">
                            <div class="d-flex flex-row">
                                <v-icon small color="orange darken-2" v-if="resultBy == 'salescontact_id'">fiber_manual_record</v-icon>
                                <v-icon small color="blue lighten-2" v-if="basedOn == 'salescontact_id'">fiber_manual_record</v-icon>
                                <span>{{ item.MarketingReport.marketing }}</span>
                            </div>
                        </template>
                        <template v-slot:item.MarketingReport.origin="{item}">
                            <div class="d-flex flex-row">
                                <v-icon small color="orange darken-2" v-if="resultBy == 'origin_id'">fiber_manual_record</v-icon>
                                <v-icon small color="blue lighten-2" v-if="basedOn == 'origin_id'">fiber_manual_record</v-icon>
                                <span>{{ item.MarketingReport.origin }}</span>
                            </div>
                        </template>
                        <template v-slot:item.MarketingReport.marketSegment="{item}">
                            <div class="d-flex flex-row">
                                <v-icon small color="orange darken-2" v-if="resultBy == 'marketsegment_id'">fiber_manual_record</v-icon>
                                <v-icon small color="blue lighten-2" v-if="basedOn == 'marketsegment_id'">fiber_manual_record</v-icon>
                                <span>{{ item.MarketingReport.marketSegment }}</span>
                            </div>
                        </template>
                        <template v-slot:item.MarketingReport.product="{item}">
                            <div class="d-flex flex-row">
                                <v-icon small color="orange darken-2" v-if="resultBy == 'productgroup_id'">fiber_manual_record</v-icon>
                                <v-icon small color="blue lighten-2" v-if="basedOn == 'productgroup_id'">fiber_manual_record</v-icon>
                                <span>{{ item.MarketingReport.product }}</span>
                            </div>
                        </template>
                        <template v-slot:item.MarketingReport.species="{item}">
                            <div class="d-flex flex-row">
                                <v-icon small color="orange darken-2" v-if="resultBy == 'species_id'">fiber_manual_record</v-icon>
                                <v-icon small color="blue lighten-2" v-if="basedOn == 'species_id'">fiber_manual_record</v-icon>
                                <span>{{ item.MarketingReport.species }}</span>
                            </div>
                        </template>
                        <template v-slot:item.MarketingReport.destination="{item}">
                            <div class="d-flex flex-row">
                                <v-icon small color="orange darken-2" v-if="resultBy == 'destination_id'">fiber_manual_record</v-icon>
                                <v-icon small color="blue lighten-2" v-if="basedOn == 'destination_id'">fiber_manual_record</v-icon>
                                <span>{{ item.MarketingReport.destination }}</span>
                            </div>
                        </template>
                        <template v-slot:item.MarketingReport.date="{item}">
                            {{ formatDate(item.MarketingReport.date) }}
                        </template>
                        <template v-slot:item.MarketingReport.number="{ item }">
                            <span class="font-weight-bold">{{ item.MarketingReport.number }}</span>
                        </template>
                        <template v-slot:item.MarketingReport.cost="{item}">
                            <span class="text-no-wrap">{{ item.MarketingReport.currency + " " + formatThisNumber(item.MarketingReport.cost, '0,0') }}</span>
                        </template>
                        <template v-slot:item.MarketingReport.value="{item}">
                            <span class="text-no-wrap">{{ item.MarketingReport.currency + " " + formatThisNumber(item.MarketingReport.value, '0,0') }}</span>
                        </template>
                        <template v-slot:item.MarketingReport.osd="{item}">
                            {{ formatDate(item.MarketingReport.osd) }}
                        </template>
                        <template v-slot:item.MarketingReport.etd="{item}">
                            {{ formatDate(item.MarketingReport.etd) }}
                        </template>
                        <template v-slot:item.MarketingReport.eta="{item}">
                            {{ formatDate(item.MarketingReport.eta) }}
                        </template>
                        <template v-slot:item.MarketingReport.margin="{item}">
                            <span class="text-no-wrap">{{ item.MarketingReport.currency + " " + formatThisNumber(item.MarketingReport.margin, '0,0') }}</span>
                        </template>
                        <template v-slot:item.MarketingReport.marginPct="{item}">
                            <span class="text-no-wrap" v-if="['DS','CS','SS','WH'].includes(item.MarketingReport.type)">{{ formatThisNumber(item.MarketingReport.marginPct,'0.0') }}</span>
                            <span class="text-no-wrap" v-if="['PO','EXPPO'].includes(item.MarketingReport.type)">{{ $t('message.na') }}</span>
                        </template>
                        <template v-slot:item.MarketingReport.invoiceDate="{item}">
                            {{ item.MarketingReport.invoiceDate != 'N/A' ? formatDate(item.MarketingReport.invoiceDate) : item.MarketingReport.invoiceDate }}
                        </template>
                    </v-data-table>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import {api} from "Api";
import {formatDate, formatDateShort, log, numberFormat} from "Helpers/helpers";
import {v4 as uuidv4} from "uuid";

const ExportTableReportDetail = () => import("Components/Appic/MarketingReport/ExportTableReportDetail");

export default {
    name: "ReportDetail",
    components: {ExportTableReportDetail},
    data() {
        return {
            basedOn: null,
            exportConditions: {},
            loader: false,
            loading: {
                fetch: false,
                filterResults: false
            },
            reportResults: [],
            resultBy: null,
            searchTerm: null,
            tableHeight: '500'
        }
    },
    computed: {
        headers () {
            return [
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'MarketingReport.id',
                    sortable: false,
                    searchable: false,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 2,
                    text: this.$t('message.date'),
                    value: 'MarketingReport.date',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 3,
                    text: this.$t('message.type'),
                    value: 'MarketingReport.type',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 4,
                    text: this.$t('message.number'),
                    value: 'MarketingReport.number',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 5,
                    text: this.$t('message.buyer'),
                    value: 'MarketingReport.buyer',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 6,
                    text: this.$t('message.spl'),
                    value: 'MarketingReport.supplier',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 7,
                    text: this.$t('message.marketing'),
                    value: 'MarketingReport.marketing',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 8,
                    text: this.$t('message.destination'),
                    value: 'MarketingReport.destination',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 9,
                    text: this.$t('message.origin'),
                    value: 'MarketingReport.origin',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 10,
                    text: this.$t('message.marketSegment'),
                    value: 'MarketingReport.marketSegment',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 11,
                    text: this.$t('message.product'),
                    value: 'MarketingReport.product',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 12,
                    text: this.$t('message.species'),
                    value: 'MarketingReport.species',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 13,
                    text: this.$t('message.contractCost'),
                    value: 'MarketingReport.cost',
                    sortable: true,
                    searchable: false,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 14,
                    text: this.$t('message.contractValue'),
                    value: 'MarketingReport.value',
                    sortable: true,
                    searchable: false,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 15,
                    text: this.$t('message.osd'),
                    value: 'MarketingReport.osd',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 16,
                    text: this.$t('message.etd'),
                    value: 'MarketingReport.etd',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 17,
                    text: this.$t('message.eta'),
                    value: 'MarketingReport.eta',
                    sortable: true,
                    searchable: true,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 18,
                    text: this.$t('message.margin'),
                    value: 'MarketingReport.margin',
                    sortable: true,
                    searchable: false,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 19,
                    text: this.$t('message.marginPct'),
                    value: 'MarketingReport.marginPct',
                    sortable: true,
                    searchable: false,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 20,
                    text: this.$t('message.invoiceDebitNote'),
                    value: 'MarketingReport.invoice',
                    sortable: true,
                    searchable: false,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                },
                {
                    id: 21,
                    text: this.$t('message.invoiceDebitNoteDate'),
                    value: 'MarketingReport.invoiceDate',
                    sortable: true,
                    searchable: false,
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                }
            ]
        }
    },
    methods: {
        filterResults () {},
        formatDate,
        formatDateShort,
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        getReportDetail () {
            this.loading.fetch = true
            let resultId = this.$route.query.resultId ? this.$route.query.resultId : ''
            let groupById = this.$route.query.groupById ? this.$route.query.groupById : ''
            let reportHash = this.$route.query.reportHash ? this.$route.query.reportHash : ''
            let reportYear = this.$route.query.reportYear ? this.$route.query.reportYear : null
            let url = '/reports/marketing/detail/' + resultId + '/' + reportHash
            if(groupById != '' && resultId != ''){
                url = '/reports/marketing/detail/' + resultId + '/' + groupById + '/' + reportHash
            } else if(groupById != '' && resultId == ''){
                url = '/reports/marketing/group-detail/' + groupById + '/' + reportHash
            }
            if(reportYear != null){
                url += '?year=' + reportYear
            }
            api
                .get(url)
                .then((response) => {
                    this.reportResults = response.data.data
                    this.basedOn = response.data.conditions.basedOn
                    this.resultBy = response.data.conditions.resultBy
                    this.loading.fetch = false
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.reportDetailNotLoaded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.fetch = false
                })
        },
        handleResize() {
            this.tableHeight = window.innerHeight - (150);
        },
        resetSearch() {
            this.searchTerm = null
            this.getReportDetail()
        },
        viewPdf (contract) {
            let document = contract.MarketingReport.number
            if(['DS','SS'].includes(contract.MarketingReport.type)) {
                let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + encodeURIComponent(document)
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/contracts/print/'
                    + contract.MarketingReport.id
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank")
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            } else if(contract.MarketingReport.type == 'PO'){
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + encodeURIComponent(document)
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/purchaseorders/print/'
                    + contract.MarketingReport.poId
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank")
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            } else if(contract.MarketingReport.type == 'CS'){
                if(contract.MarketingReport.document_version != null){
                    if (contract.MarketingReport.SignedOrder.location != null) {
                        let tab = window.open(contract.MarketingReport.SignedOrder.location, "_blank")
                        tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                        this.openedTabs.push(tab.name)
                    } else {
                        if(contract.MarketingReport.TmsSignedOrder.location != null){
                            let tab = window.open(contract.Sale.TmsSignedOrder.location,"_blank")
                            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                            this.openedTabs.push(tab.name)
                        } else {
                            this.dialogs.error = true
                            this.dialogs.error_message = this.$t('message.signedContractNotAvailable')
                        }
                    }
                } else {
                    if(contract.MarketingReport.TmsSignedOrder.location != null){
                        let tab = window.open(contract.MarketingReport.TmsSignedOrder.location,"_blank")
                        tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                        this.openedTabs.push(tab.name)
                    } else {
                        this.dialogs.error = true
                        this.dialogs.error_message = this.$t('message.signedContractNotAvailable')
                    }
                }
            } else {
                return false
            }
        },
        viewWhSalesInvoicePdf (invoice) {
            let document = 'INV ' + invoice.MarketingReport.number
            let user = JSON.parse(localStorage.getItem('user'))
            let tab = window.open(
                process.env.VUE_APP_PDF_SERVER
                + '/api/render/'
                + encodeURIComponent(document)
                + '?url='
                + process.env.VUE_APP_BASE_URL
                + '/v1/localsales/print/'
                + invoice.MarketingReport.id
                + '/si/'
                + uuidv4()
                + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                , "_blank")
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        this.handleResize()
        this.getReportDetail()
        this.$title =  this.$t('message.titles.marketingReportDetail')
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style>
.font-xs {
    font-size: 0.60rem;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
.v-data-table-header th {
    white-space: nowrap;
}
</style>